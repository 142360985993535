<template>
  <div id="app" class="Site">
    <Navbar v-if="['login', 'register'].indexOf($route.name) == -1" />
    <router-view class="Site-content" />
    <Footer v-if="['login', 'register'].indexOf($route.name) == -1" />
    <ItemDetails />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import ItemDetails from "@/components/ItemDetails.vue";

export default {
  name: "app",
  components: {
    Navbar,
    Footer,
    ItemDetails
  },
  data() {
    return {
      localToken: null
    };
  },
  mounted() {
    console.log("APP MOUNTED!");
    console.log("Begin User Check!");
    if (!this.$store.state.isLoged) {
      this.checkLoged();
    } else {
      this.checkRequests();
      this.getCategories();
    }
    // } else {
    //   this.$router.push({ path: "/category/all" }).catch(err => {});
    // }
  },
  methods: {
    checkLoged: function() {
      if (localStorage.iutoken) {
        console.log("Validating Token..");
        //console.log("Token:" + localStorage.token);
        //begin post --------------------------------------------
        this.axios
          .post("user/validate.php", {
            token: localStorage.iutoken,
            action: "user"
          })
          .then(response => {
            //this.isLoading = false;
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("DO_LOGIN", true);
              this.$store.commit("RESET_USER", response.data.data);
              this.$store.commit("RESET_TOKEN", localStorage.iutoken);
              console.log("Loged!");
              this.getCategories();
              this.$router.push({ path: "/category/all" }).catch(err => {});
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
    getCategories: function() {
        console.log("Loading Categories..");
        //begin post --------------------------------------------
        this.axios
          .post("user/manage_user.php", {
            token: localStorage.iutoken,
            action: "getCategories"
          })
          .then(response => {
            console.log(response.data);
            if (response.data.success) {
              this.$store.commit("SET_CATEGORIES", response.data.categories);
            }else{
              console.log("unable to load categories");
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------

    },
    checkRequests: function() {
      if (localStorage.requests) {
        let requests = localStorage.requests.split(",");
        console.log("Has Requests");
        for (let request of requests) {
          this.$store.commit("ADD_NUM", request);
        }
      } else {
        console.log("no requests found");
      }
    }
  },
  watch: {
    '$store.state.isLoged': function(isLoged) {
      if(isLoged){
        this.getCategories();
      }
    }
  }
  // watch: {
  //   $route(to, from) {
  //     // clear alert on location change
  //     this.$store.dispatch("alert/clear");
  //   }
  // }
};
</script>
<style>
/* .header {
  background: url("~@/assets/bg.png") no-repeat;
  background-size: cover;
  background-position: top center;
} */
.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}
</style>
<style lang="scss">
//Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@400;600&family=Roboto:wght@100;300;400&display=swap'); 
//font-family: 'Bebas Neue', cursive;
//font-family: 'Poppins', sans-serif;
//font-family: 'Roboto', sans-serif;

// Import Fontawesome
@import 'static/fontawesome/css/all.min.css';
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #7CBC4B;
$success: #74b570;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #F4F4F4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
$body-background-color: #fff;
$content-heading-color: #000000;

$ddportable: #ffcd0b;
$ddmodular: #f37422;
$ddcustom: #ed3a25;
$ddgraphic: #71cee3;
$ddproject: #b393b0;

$ddgreen: #7CBC4B;
$ddgreen-light: #8dc457;
$ddgreen-dark: #669f42;
$ddgreen-dark2: #5f9340;

$ddgray: #4a4f54;
$ddgray2: #41464c;
$ddgray3: #383c41;
$ddgray4: #2e3136;
$ddgray5: #23262b;

$ddlight: #97a3ae;
$ddlight2: #d8dfe1;
$ddlight3: #f7f7f7;

$navbar-item-color: $ddgray!important;
$navbar-dropdown-arrow: $ddgray!important;

$footer-background-color: $ddgray;
$footer-color: $ddlight2;
$footer-padding: 3rem 1.5rem 3rem;

//$family-primary: 'Poppins', sans-serif;
$family-primary: 'Roboto', sans-serif;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "mydark": ($mydark, $white),
    "mylight": ($mylight, $black),
    "filter": ($filter, $white),
    "ddportable": ($ddportable, $white),
    "ddmodular": ($ddmodular, $white),
    "ddcustom": ($ddcustom, $white),
    "ddgraphic": ($ddgraphic, $white),
    "ddproject": ($ddproject, $white),    
    "ddgreen": ($ddgreen, $white),
    "ddgreen-light": ($ddgreen-light, $white),
    "ddgreen-dark": ($ddgreen-dark, $white),
    "ddgreen-dark2": ($ddgreen-dark2, $white),
    "ddgray": ($ddgray, $white),
    "ddgray2": ($ddgray2, $white),
    "ddgray3": ($ddgray3, $white),
    "ddgray4": ($ddgray4, $white),
    "ddgray5": ($ddgray5, $white),
    "ddlight": ($ddlight, $white),
    "ddlight2": ($ddlight2, $ddgray),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color:$ddlight;
$input-icon-active-color:$ddgray;

.header{
  background: #d8dfe1;
  background: linear-gradient(333deg, #d8dfe1 0%, #fff 100%);
}

.loginbg{
  background: #f7f7f7;
  // background: linear-gradient(333deg, #d8dfe1 0%, #fff 100%);
}

.ddsublogo{
  font-family: 'Bebas Neue';
  line-height: 1;
  text-align: center;
  font-size: 1.6rem;
  color: $ddlight!important;
  font-weight: 100!important;
}

.navbar-item{
  font-family: 'Poppins', sans-serif;
  font-weight: 600!important;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.menu{
  font-family: 'Poppins', sans-serif;
 
  text-transform: uppercase;
  font-size: 0.9em!important;
}

.footer-link:hover{
  color: $ddlight!important 
}

.ddtitle{
  font-family: 'Bebas Neue';
  color: $ddgray !important;
  line-height: 1;
}
.dditemtitle{
  font-family: 'Bebas Neue';
  font-size: 2em;
  text-transform: uppercase;
  color: $ddgray!important;
  line-height: 1!important;
}
.header-title{
  font-family: 'Bebas Neue';
  font-size: 3.5rem;
  line-height: 1;
}
.header-subtitle{
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: $ddlight!important;
  font-weight: 600!important;
}

.subtitle{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 600!important;
  color: $ddlight!important;
}

.button{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 600!important;
}

.ddseparator{
  max-width:80px;
  border-top: 2px solid $ddgreen;
}

.label{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 400!important;
  color: $ddlight!important;
}

.card-header-title{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 600!important;
  color: $ddgreen!important;  
}

.order-project{
  font-family: 'Bebas Neue';
  font-size: 2em;
  text-transform: uppercase;
  color: $ddgray!important;
  line-height: 1!important;
}
.order-id{
  font-family: 'Poppins', sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: 600!important;
  color: $ddlight!important;
}
.order-deliver{
  font-family: 'Poppins', sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: 600!important;
  color: $ddgreen!important;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>