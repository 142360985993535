import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
//import "buefy/dist/buefy.css";
import Lightbox from "vue-easy-lightbox";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import VueMasonry from "vue-masonry-css";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import "bulma-divider/dist/css/bulma-divider.min.css";

Vue.config.productionTip = false;

Vue.prototype.$mySite = {
  title: "Display Depot",
  url: "https://depot.displaydynamics.ca/",
  image: "https://depot.displaydynamics.ca/media/share.png",
  api: "https://depot.displaydynamics.ca/user_api/",
  filesURL: "https://depot.displaydynamics.ca/userfiles/",
  imagesURL: "https://depot.displaydynamics.ca/userimages/",
  logosURL: "https://depot.displaydynamics.ca/userlogo/"
};

axios.defaults.baseURL = "https://depot.displaydynamics.ca/user_api/";

Vue.use(Buefy, {
  defaultIconPack: "fas"
});
Vue.use(VueAxios, axios);
Vue.use(Lightbox);
Vue.use(Autocomplete);
Vue.use(VueMasonry);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
