import Vue from "vue";
import Router from "vue-router";
import store from "./store";
//import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  // base: "/indexer/",
  routes: [
    {
      path: "/",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Login.vue"),
      beforeEnter: (to, from, next) => {
        if (store.state.isLoged) {
          next("/category/all");
        } else {
          next();
        }
      }
    },
    {
      path: "/category/:cname?/:page?",
      name: "category",
      component: () =>
        import(/* webpackChunkName: "catalog" */ "./views/Catalog.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    {
      path: "/my-account",
      name: "my-account",
      // route level code-splitting
      // this generates a separate chunk (account.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "account" */ "./views/MyAccount.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    {
      path: "/order-history",
      name: "order-history",
      // route level code-splitting
      // this generates a separate chunk (history.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "history" */ "./views/OrderHistory.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    {
      path: "/requests",
      name: "requests",
      // route level code-splitting
      // this generates a separate chunk (requests.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "requests" */ "./views/Requests.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    {
      path: "/terms",
      name: "terms",
      // route level code-splitting
      // this generates a separate chunk (terms.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "terms" */ "./views/Terms.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      // route level code-splitting
      // this generates a separate chunk (privacy.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue"),
      beforeEnter: (to, from, next) => {
        if (!store.state.isLoged) {
          next("/");
        } else {
          next();
        }
      }
    },
    // {
    //   path: "/test",
    //   name: "test",
    //   // route level code-splitting
    //   // this generates a separate chunk (test.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "privacy" */ "./views/Test.vue")
    // },
    // {
    //   path: "*",
    //   name: "404",
    //   component: () => import(/* webpackChunkName: "404" */ "./views/Error404.vue")
    // },
    // otherwise redirect to home
    { path: "*", redirect: "/" }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});
