<template>
  <div v-if="isLoged">
    <footer class="footer">
      <div class="container">
        <div class="columns is-desktop">
          <div class="column">
             <img src="~@/assets/dd_logo_footer.svg" alt="Display Depot" width="300" />
          </div>
          <div class="column has-text-centered has-text-left-mobile mt-2">
            {{ currentYear }} &copy; Display Dynamics Ltd. | All Rights Reserved.
          </div>
          <div class="column has-text-right has-text-left-mobile mt-2">
            <router-link class="footer-link" to="/terms">Terms &amp; Conditions</router-link> |
            <router-link class="footer-link" to="/privacy">Privacy &amp; Cookies</router-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    ...mapState(["isLoged"])
  }
};
</script>
