<template>
  <b-navbar type="is-white" wrapper-class="container" fixed-top v-if="isLoged" class="shadow">
    <template slot="brand">
      <b-navbar-item @click="goTo('all',0)">
        <img src="~@/assets/depot_logo_dark.svg" alt="Display Depot" width="120" />
      </b-navbar-item>
    </template>
    <template slot="start">
     <b-navbar-item tag="div" class="navsubtitle"> Online Display Inventory Management Portal</b-navbar-item>
    </template>

    <template slot="end">
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Home
      </b-navbar-item>      -->
      <b-navbar-item tag="div" v-if="!isLoged">
        <div class="buttons">
          <button class="button is-light" @click="showLogin()">
            Log In
          </button>
        </div>
      </b-navbar-item>

      <b-navbar-item tag="div" v-if="isLoged && rNum > 0">
        <div class="buttons">
          <b-button class="button is-primary" tag="router-link" to="/requests">
            <span>Requests</span>
            <span class="tag tagmargin">
              {{ rNum }} Item<span v-if="rNum !== 1">s</span>
            </span>
          </b-button>
        </div>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: '/category/all' }">
        Catalog
      </b-navbar-item>

      <b-navbar-dropdown label="Account" v-if="isLoged">
        <b-navbar-item tag="router-link" :to="{ path: '/my-account' }">
          Contact Info
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/requests' }">
          Requests
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/order-history' }">
          Order History
        </b-navbar-item>
        <hr class="navbar-divider" />
        <b-navbar-item @click="doLogout()">
          Log Out
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import modalsMixin from "@/mixins/modalsMixin";
import { mapState } from "vuex";
export default {
  name: "Navbar",
  mixins: [modalsMixin],
  methods: {
    // showLogin() {
    //   //change fortm state
    //   console.log(this.$store.state.loginShow);
    //   this.$store.commit("SHOW_MODAL", { modal: "loginShow", state: true });
    //   console.log("Open Login Module");
    //   console.log(this.$store.state.loginShow);
    //   console.log(this.$store.state);
    // },
    doLogout() {
      this.$store.dispatch("DO_LOGOUT");
      this.$router.push({ path: "/" });
    },
    goTo(page,id){
      this.$store.commit("SET_FILTER_CAT", id);      
      if(this.$route.path!=='/category/' + page){
        this.$router.push({ path: '/category/' + page });
      }
    },
  },
  computed: {
    // isLoged: function() {
    //   return this.$store.state.isLoged;
    // }
    ...mapState(["isLoged", "rNum"]),
    currentPage() {
      return this.$route.path;
    }
  }
};
</script>
<style scoped>
.navbar-item img {
  max-height: 3rem;
}
.tagmargin {
  margin-left: 10px;
}
.shadow{
  box-shadow: 0px 4px 10px -4px rgba(0,0,0,0.17);
}
.navsubtitle{
  font-family: 'Bebas Neue';
  border-left: 1px solid #dadfe4;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem!important;
  color: #97a3ae!important;
  font-weight: 100!important;
  padding: 5px 0 0 15px;

}
</style>
