import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import { alert } from "./store/alert.module";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

//getter: this.$store.getters.IS_USER
//mutation: this.$store.commit("SET_USER", response.data.data);
//action: this.$store.dispatch("SET_USERDATA", response.data);

//watch: {"$route.params.cname": function(cname) {}}
//computed: {isUser () {return this.$store.getters.IS_USER}}

export default new Vuex.Store({
  modules: {
    alert
  },
  state: {
    loginShow: false,
    isLoged: false,
    isUser: {
      cid: null,
      name: null,
      email: null,
      status: null,
      logo: null
    },
    isToken: null,
    itemShow: false,
    categories: null,
    itemData: {
      title: null,
      images: {
        image:null
      }
    },
    itemEditor: false,
    itemEditID: "",
    contactShow: false,
    registerShow: false,
    rNum: 0,
    requests: [],
    filterTags: null,
    filterCat:0,
    mutation: false
  },
  getters: {
    IS_LOGED: state => state.isLoged,
    IS_USER: state => state.isUser,
    //IS_JWT: () => localStorage.iutoken,
    IS_JWT: state => state.isToken,
    GET_LOGO: state => state.isUser.logo,
    REQUESTS: state => state.requests,
    TAGS: state => {
      if (state.itemData.tags !== undefined) {
        return state.itemData.tags.split(",");
      }
    },
    IS_MUTATION: state => state.mutation
  },
  mutations: {
    SET_MUTATION(state, payload) {
      //state.filterTags.push(payload);
      state.mutation = payload;
      console.log("Data change!");
    },
    SET_FILTER_TAG(state, payload) {
      //state.filterTags.push(payload);
      state.filterTags = payload;
      //console.table(state.filterTags);
    },
    SET_FILTER_CAT(state, payload) {
      state.filterCat = payload;
    },
    SET_ITEMDATA(state, payload) {
      state.itemData = payload;
    },
    SHOW_MODAL(state, payload) {
      //state[Object.keys(payload)[0]] = Object.values(payload)[0];
      state[payload.modal] = payload.state;
    },
    DO_LOGIN(state, payload) {
      state.isLoged = payload;
    },
    SET_USER(state, payload) {
      console.log("user data:" + JSON.stringify(payload));
      state.isUser.name = payload.name;
      state.isUser.email = payload.email;
      state.isUser.status = payload.status;
      state.isUser.cid = payload.cid;
      state.isUser.logo = payload.logo;
      localStorage.iuemail = payload.email;
    },
    RESET_USER(state, payload) {
      console.log("user data:" + JSON.stringify(payload));
      state.isUser.name = payload.name;
      state.isUser.email = payload.email;
      state.isUser.status = payload.status;
      state.isUser.cid = payload.cid;
      state.isUser.logo = payload.logo;
    },
    SET_TOKEN(state, payload) {
      console.log("new local token:" + payload.jwt);
      localStorage.iutoken = payload.jwt;
      state.isToken = payload.jwt;
    },
    RESET_TOKEN(state, payload) {
      state.isToken = payload;
    },
    UNSET_ALL(state) {
      state.isUser.name = null;
      state.isUser.email = null;
      state.isUser.status = null;
      state.isUser.cid = null;
      state.isUser.logo = null;
      localStorage.removeItem("iutoken");
      localStorage.removeItem("requests");
      state.isLoged = false;
    },
    SET_CATEGORIES(state, payload) {
      console.log("setting categories");
      console.log(payload);
      state.categories = payload;
    },
    DO_REGISTER(state, payload) {
      state.isLoged = payload;
    },
    ADD_NUM(state, payload) {
      state.requests.push(payload);
      localStorage.requests = state.requests.join(",");
      state.rNum = state.requests.length;
      console.table(state.requests);
    },
    REM_NUM(state, payload) {
      state.requests = state.requests.filter(
        arrayItem => arrayItem !== payload
      );
      state.rNum = state.requests.length;
      localStorage.requests = state.requests.join(",");
    },
    REM_ALL(state) {
      state.requests = [];
      state.rNum = 0;
      localStorage.removeItem("requests");
    },
    SET_EDIT_ID(state, payload) {
      state.itemEditID = payload;
    }
  },
  actions: {
    SET_USERDATA({ commit }, payload) {
      console.log("action payload" + JSON.stringify(payload));
      commit("SET_USER", payload);
      commit("SET_TOKEN", payload);
    },
    DO_LOGOUT({ commit }) {
      commit("UNSET_ALL");
    }
  }
});
