export default {
  data() {
    return {};
  },
  methods: {
    showLogin() {
      this.$store.commit("SHOW_MODAL", {
        modal: "loginShow",
        state: true
      });
    },
    showRegister() {
      this.$store.commit("SHOW_MODAL", {
        modal: "registerShow",
        state: true
      });
    },
    doLogout() {
      this.$store.commit("DO_LOGIN", false);
      this.$router.push({path: '/'});
    }
  }
};
