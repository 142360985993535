<template>
  <div>
    <div v-if="islocation == '1'">
      <span class="has-text-success iconmargin"
        ><i class="fas fa-circle"></i></span
      >In-House
    </div>
    <div v-if="islocation == '2'">
      <span class="has-text-warning iconmargin"
        ><i class="fas fa-circle"></i></span
      >Pending
    </div>
    <div v-if="islocation == '0'">
      <span class="has-text-grey-lighter iconmargin"
        ><i class="fas fa-circle"></i></span
      >Away
    </div>
  </div>
</template>

<script>
export default {
  name: "itemLocation",
  props: {
    islocation: String
  }
}; //end export
</script>

<style scoped>
.iconmargin {
  margin-right: 5px;
}
</style>
