<template>
  <section>
    <b-modal
      :active.sync="isItemDetailsActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <div class="modal-card-title">Item information</div>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-vcentered">
              <b-carousel :indicator-inside="false" :pause-info="false">
                  <b-carousel-item key="-1">
                      <span class="image">
                        <img :src="$mySite.imagesURL + item.images.image">
                      </span>
                  </b-carousel-item>
                  <b-carousel-item v-for="(image, i) in item.images.gallery" :key="i">
                      <span class="image">
                        <img :src="getImgUrl(image)">
                      </span>
                  </b-carousel-item>
              </b-carousel>
            </div>
            <div class="column">
              <div class="content">
                <div class="ddtitle is-size-2" v-html="item.title"></div>
                <div class="ddseparator my-4"></div>
                <p><strong>Description:</strong> <span v-html="item.description"></span></p>
                <p><strong>Storage:</strong> {{ item.storage }}</p>
                <div class="tags">
                  <span v-for="(tag, index) in tags" :key="tag+index">
                    <a class="tag is-light" @click="filterTag(tag,'is-primary')" v-html="tag"></a>
                  </span>
                </div>
                <div class="tags">
                  <span v-for="(mark, index) in marks" :key="mark+index">
                    <a class="tag is-danger" @click="filterTag(mark,'is-danger')" v-html="mark"></a>
                  </span>
                </div>
                <p>
                  <strong>Location:</strong
                  ><ItemLocation :islocation="item.location" />
                </p>
                <div v-if="hasArddess & (item.location == '0')">
                  {{ currentOrder.address }}, {{ currentOrder.postal }},
                  {{ currentOrder.state }}, {{ currentOrder.country }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            v-if="!isRequested & (item.location == '1')"
            class="button is-primary"
            @click="addR(item.id)"
          >
            Request
          </button>
          <button
            v-if="isRequested & (item.location == '1')"
            class="button is-danger"
            @click="removeR(item.id)"
          >
            Unrequest
          </button>
          <button class="button" type="button" @click="close()">Close</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ItemLocation from "@/components/ItemLocation.vue";
export default {
  name: "ItemDetails",
  components: {
    ItemLocation
  },
  data() {
    return {
      currentOrder: {
        address: ""
      },
      hasArddess: false,
      imgLoaded: false
    };
  },
  methods: {
    close() {
      //this.isComponentModalActive = false;
      this.imgLoaded = false;
      this.hasArddess = false;
      this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: false });
    },
    addR(id) {
      //console.log(id);
      this.$store.commit("ADD_NUM", id);
    },
    removeR(id) {
      this.$store.commit("REM_NUM", id);
    },
    filterTag(tag,type) {
      this.$store.commit("SET_FILTER_TAG", {"tag":tag,"type":type});
    },
    getLocation() {
      this.axios
        .post("data/manage_orders.php", {
          token: this.$store.getters.IS_JWT,
          action: "getOrder",
          orderID: this.item.oid
        })
        .then(response => {
          console.log(response.data);
          //console.log(this.item.oid);

          if (response.data.success) {
            this.hasArddess = true;
            this.currentOrder = response.data.order;
          } else {
            this.hasArddess = false;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    getImgUrl(i){
      return this.$mySite.imagesURL + i;
    }
  },
  computed: {
    isItemDetailsActive: {
      get: function() {
        return this.$store.state.itemShow;
      },
      // setter
      set: function() {
        this.$store.commit("SHOW_MODAL", {
          modal: "itemShow",
          state: false
        });
        return false;
      }
    },
    item: {
      get: function() {
        return this.$store.state.itemData;
      }
    },
    isRequested: {
      get: function() {
        return this.$store.getters.REQUESTS.includes(this.item.id);
      }
    },
    tags: {
      get: function() {
        if(this.$store.state.itemData.tags) return this.$store.state.itemData.tags.split(",");
      }
    },
    marks: {
      get: function() {
        if(this.$store.state.itemData.marks) return this.$store.state.itemData.marks.split(",");
      }
    }
  },
  watch: {
    item: function(value) {
      this.imgLoaded = false;
      this.getLocation();
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: flex-end; /* flex-end; */
}
.modal-card-title{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 400!important;
}
.tags span {
  margin-right: 5px;
}
.image {
  border: solid 1px;
  border-color: silver;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
}
.imgconteiner {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.imgvisible {
  opacity: 1;
}
.imgpreloader {
  padding-top: 100px;
  color: #79b943;
}
</style>
